<template>
  <div>
    <DataTable
      :cols="cols"
      :request-params="{ with: ['booking'] }"
      default-order-by="created_at"
      path="bikes"
    >
      <template v-slot:item.customer="{ item: { booking } }">
        <v-chip small>{{ booking ? booking.customer_name : '-' }}</v-chip>
      </template>
      <template v-slot:item.collection_date="{ item: { booking } }">
        <v-chip small>{{ booking ? $momentDate(booking.collection_date) : '-' }}</v-chip>
      </template>
      <template v-slot:item.return_date="{ item: { booking } }">
        <v-chip small>{{ booking ? $momentDate(booking.return_date) : '-' }}</v-chip>
      </template>
    </DataTable>
  </div>
</template>
<script type="text/javascript">
import DataTable from '@/components/DataTable'

export default {
  name: 'BookingBikes',

  components: {
    DataTable
  },

  data () {
    return {
      cols: [
        { text: 'Ref', value: 'ref' },
        { text: 'Bike', value: 'name' },
        { text: 'Customer', value: 'customer' },
        { text: 'Collection Date', value: 'collection_date' },
        { text: 'Return Date', value: 'return_date' },
      ]
    }
  }
}
</script>
